import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Newsletter from "../Form/Newsletter";

const ValueProps = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      subscribe: file(
        relativePath: { eq: "repeating/Subscribe/subscribe.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
    }
  `);

  return (
    <section className={`pb-20 md:pb-32 ${className}`}>
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div className="order-2 md:order-1">
            <h2>Blogs and Pods</h2>
            <p className="mb-10">
              Whether you prefer the written word or the spoken word, we've got
              meaningful commentary that will help you focus on what's important
              when it comes to your financial situation and taxes.
            </p>

            <Newsletter />
          </div>
          <div className="order-1 md:order-2">
            <GatsbyImage
              image={data.subscribe.childImageSharp.gatsbyImageData}
              className="z-0 w-full md:rounded-3xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
