import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import GraphQLErrorList from "../Blog/graphql-error-list";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
} from "../../lib/helpers";
import ModalVideo from "../Modal/ModalVideo";

const RecentPosts = ({ props, className }) => {
  const data = useStaticQuery(graphql`
    {
      posts: allSanityPost(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            title
            slug {
              current
            }
          }
        }
      }
      vlogs: allSanityVlog(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            id
            title
            vimeoVideoID
            vimeoVideoPoster
          }
        }
      }
      podcasts: allSanityPodcast(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const [vimeoID, setVimeoID] = useState(false);

  const errors = props;

  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const blogNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const vlogNodes = (data || {}).vlogs ? mapEdgesToNodes(data.vlogs) : [];

  const podcastNodes = (data || {}).podcasts
    ? mapEdgesToNodes(data.podcasts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <>
      <section className={` ${className || "pb-20 md:pb-32"}`}>
        <div className="container">
          <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-6 lg:gap-x-8">
            {blogNodes.length > 0 &&
              blogNodes.slice(0, 1).map((node) => (
                <div key={node.id}>
                  <AniLink
                    fade
                    to={`/${node.slug.current}/`}
                    className="block h-full rounded-xl bg-primary-50 p-6 no-underline"
                  >
                    <div className="mb-2.5 inline-flex rounded-sm border-2 border-primary-500 bg-white px-1.5 text-sm font-medium uppercase tracking-wide text-primary-500">
                      Blog
                    </div>
                    <p className="heading-five mb-0">{node.title}</p>
                  </AniLink>
                </div>
              ))}

            {vlogNodes.length > 0 &&
              vlogNodes.slice(0, 1).map((node, i) => (
                <div key={node.id}>
                  <button
                    data-modal-open="modal-vlog-video"
                    onClick={() => setVimeoID(node.vimeoVideoID)}
                    className="block h-full w-full rounded-xl bg-primary-50 p-6 text-left no-underline"
                  >
                    <div className="mb-2.5 inline-flex rounded-sm border-2 border-primary-500 bg-white px-1.5 text-sm font-medium uppercase tracking-wide text-primary-500">
                      Vlog
                    </div>
                    <p className="heading-five mb-0">{node.title}</p>
                  </button>
                </div>
              ))}

            {podcastNodes.length > 0 &&
              podcastNodes.slice(0, 1).map((node) => (
                <div key={node.id}>
                  <AniLink
                    fade
                    to={`/${node.slug.current}/`}
                    className="block h-full rounded-xl bg-primary-50 p-6 no-underline"
                  >
                    <div className="mb-2.5 inline-flex rounded-sm border-2 border-primary-500 bg-white px-1.5 text-sm font-medium uppercase tracking-wide text-primary-500">
                      Podcast
                    </div>
                    <p className="heading-five mb-0">{node.title}</p>
                  </AniLink>
                </div>
              ))}
          </div>
        </div>
      </section>
      <ModalVideo
        modalId="modal-vlog-video"
        autoPlay={false}
        iframe={true}
        videoSource={`https://player.vimeo.com/video/${vimeoID}?portrait=0`}
      />
    </>
  );
};

export default RecentPosts;
